import Header from "./sections/Header";
import Footer from "./sections/Footer";

const Home = () => {
    return (
        <>
        <h1>templates/pages/Home.js</h1>
        {/* <Footer /> */}
        </>
        
    )
}

export default Home;